class Recaptcha extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = `
        <div class="form-group">
            <div class="g-recaptcha" data-sitekey="6Lf5Db0UAAAAAGoIT4bpLriHXgF0cWNn3eclao_p" data-callback="grecaptchaRes" data-expired-callback="grecaptchaExpired"></div>
            <span class="error-msg text-danger">Please validate.</span>
        </div>
        `
    }
}

customElements.define('google-recaptcha', Recaptcha);