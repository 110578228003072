import $ from 'jquery';
window.$ = $;

import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/carousel';
import 'bootstrap-select';
import 'waypoints/lib/jquery.waypoints';
import "magnific-popup";
import axios from 'axios';

import Swiper, { Pagination, EffectFade, Autoplay } from 'swiper';

import {initCareersPage, initJobDescriptionPage} from './careers';
import { validateField } from './validation';

var isProductsLinkHovered = false;
var isAboutUsLinkHovered = false;
var swiper;
let openedAccordionIndex = 0;
let winW;
let recaptchaValidate = false;
let enquiryRecaptchaValidate = false;

let loading = `
<div class="d-flex flex-column align-items-center">
    <h3>Submitting...</h3>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 30px auto;display: block;shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#3776ff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8264462809917356s" values="0 50 50;360 50 50" keyTimes="0;1"/>
        </circle>
    </svg>
</div>`;

let success = `
<div class="d-flex flex-column align-items-center">
    <h3>Submitted</h3>
    <i class="fas fa-check-circle"></i>
    <p>Sit tight! Our team will reach out to you regarding <br class="d-none d-lg-block">your enquiry soon.</p>
    <button class="btn btn-primary fs-btn btn__tall btn-smaller__text btn__air">
        <span class="text-uppercase">Continue</span>
    </button>
</div>`;

let error = `
<div class="d-flex flex-column align-items-center">
    <h3>Opps, something's wrong!</h3>
    <i class="fas fa-times-circle text-danger"></i>
    <p class="error"></p>
    <button class="btn btn-primary fs-btn btn__tall btn-smaller__text btn__air">
        <span class="text-uppercase">Back</span>
    </button>
</div>
`;

var myDefaultWhiteList = $.fn.selectpicker.Constructor.DEFAULTS.whiteList;

myDefaultWhiteList.span = [];


const isiPhone = () => {
    return (
        (navigator.platform.indexOf("iPhone") != -1) ||
        (navigator.platform.indexOf("iPod") != -1)
    );
};

// const goToPage = (location) => {
//     window.href = location;
// }

const grecaptchaRes = (e) => {
    $(this).children('.form-group').children('.g-recaptcha').siblings().hide()
    const res = grecaptcha.getResponse() || e

    if(res.length) {
        recaptchaValidate = true;
    }
}

const enquiryGrecaptchaRes = (e) => {
    $(this).children().children('div').children('.form-group').children('.g-recaptcha').siblings('.error-msg').hide()
    const res = grecaptcha.getResponse() || e

    if(res.length) {
        enquiryRecaptchaValidate = true;
    }
}

window.grecaptchaRes = grecaptchaRes;
window.enquiryGrecaptchaRes = enquiryGrecaptchaRes;

const grecaptchaExpired = () => {
    recaptchaValidate = false;
}
const enquiryGrecaptchaExpired = () => {
    enquiryRecaptchaValidate = false;
}

window.grecaptchaExpired = grecaptchaExpired;
window.enquiryGrecaptchaExpired = enquiryGrecaptchaExpired;

const backToTop = () => {
    $("html, body").animate({ scrollTop: 0 }, 600);
}

const navBarRegisterHover = () => {
    let timer;
    let timer1;
    $('#fs-products__links').mouseenter(() => {
        $('.fs-products__navcontent').css('display', 'block');
        $('#fs-products__links').addClass('hovered');
    }).mouseleave(function () {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }
        timer = setTimeout(function () {
            if (!isProductsLinkHovered) {
                $('.fs-products__navcontent').css('display', 'none');
                $('#fs-products__links').removeClass('hovered');
            }
        }, 250);
    });

    $('#fs-about-us__links').mouseenter(() => {
        $('.fs-about-us__navcontent').css('display', 'block');
        $('.about-us__nav').css('left', $('#fs-about-us__links').offset().left)
        $('#fs-about-us__links').addClass('hovered');
    }).mouseleave(function () {
        if (timer1) {
            clearTimeout(timer1);
            timer1 = null;
        }
        timer1 = setTimeout(function () {
            if (!isAboutUsLinkHovered) {
                $('.fs-about-us__navcontent').css('display', 'none');
                $('.about-us__nav').removeAttr('style')
                $('#fs-about-us__links').removeClass('hovered');
            }
        }, 250);
    });

    $(".fs-products__navcontent").mouseenter(function () {
        isProductsLinkHovered = true;
    }).mouseleave(function () {
        isProductsLinkHovered = false;
        $('#fs-products__links').trigger('mouseout');
    });

    $(".fs-about-us__navcontent").mouseenter(function () {
        isAboutUsLinkHovered = true;
    }).mouseleave(function () {
        isAboutUsLinkHovered = false;
        $('#fs-about-us__links').trigger('mouseout');
    });
}

const pageScrolled = () => {
    const $this = $('#fs-first__section');
    $this.waypoint(function (direction) {
        if (direction === 'down') {
            $('body').addClass('scrolled');
            $('#back-to-top').fadeIn(200);
        } else if (direction === 'up') {
            $('body').removeClass('scrolled');
            $('#back-to-top').fadeOut(200);
        }
    }, {
        offset: '-350px'
    });
}

const canvasNavBarSetup = () => {

    $('#fs-canvas__toggler, #fs-inner__toggler').on('click', function () {
        if ($('#fs-off_canvas__bar').hasClass('shown')) {
            $('body, html').css('overflow', 'auto');
            $('#fs-off_canvas__bar').removeClass('shown');
            $('.cover').animate({ opacity: 0, visibility: 'hidden' }, 250, function(){
                $(this).removeClass('overlay');
            })
        } else {
            $('body, html').css('overflow', 'hidden');
            $('#fs-off_canvas__bar').addClass('shown');
            $('.cover').addClass('overlay').animate({ opacity: 1, visibility: 'visible' }, 250);
        }

    });

    $('.fs-off_canvas_item__dropdown').on('click', function () {
        const _this = $(this);
        if ($(this).children('.dropdown__content').is(':visible')) {
            $(this).removeClass('clicked');
            $(this).children('.dropdown__content').slideUp();
        } else {
            $(this).children('.dropdown__content').slideDown();
            // $('.fs-off_canvas_item__dropdown').each(function () {
            //     $(this).removeClass('clicked');
            // })
            $(this).addClass('clicked');
        }
    });

    $('.fs-off_canvas_item__dropdown .dropdown__content').on('click', function (e, ele) {
        e.stopPropagation();
    });

    $(window).scroll(function () {
        const $menu = $('#fs-main__nav');
        if ($(window).scrollTop() > 60) {
            if (!isiPhone()) {
                $menu.addClass('sleep');
            }
        }
        if ($(window).scrollTop() <= 20) {
            if (!isiPhone()) {
                $menu.removeClass('sleep');
            }
        }
    });

}

const animateStats = () => {
    $('.info, .history, .stats').waypoint((dir) => {
        if (dir === 'down') {
            let stats = $('.stat-number');
            if (!stats.hasClass('animate-done')) {
                stats.each(function () {
                    stats.addClass('appear');
                    var $this = $(this);
                    jQuery({ Counter: 0 }).animate({ Counter: $this.data('value') }, {
                        duration: 1500,
                        easing: 'swing',
                        step: function (e) {
                            $this.text(Math.ceil(this.Counter));
                        },
                        complete: function (e) {
                            if ($this.hasClass('special')) {
                                $this.text($this.data('actual'));
                            } else {
                                $this.text($this.data('value'));
                            }
                            $this.addClass('animate-done');
                        }
                    });
                });

            }
        }
    },
    {
        offset: 550
    });

}

// const animatePage = (pageCheck, elements) => {
//     const isPage = $(pageCheck).length > 0;
//     if (isPage) {
//         const _anim = (element) => {
//             if ($(element).length > 0) {
//                 $(`${element} .fs-to__animate`).each(function (k) {
//                     var el = $(this);

//                     setTimeout(function () {
//                         console.log(k)
//                         el.addClass('fadeIn animated');
//                     }, k * 200, 'easeInOutExpo');

//                 });
//             }

//         }

//         const registerAnimation = (element) => {
//             $(element).waypoint(function (direction) {
//                 if (direction === 'down' && !$(this).hasClass('animated')) {
//                     setTimeout(_anim(element), 100);
//                     $(this.element).addClass('animated');
//                 }
//             }, {
//                 offset: '75%'
//             });
//         }

//         for (const e of elements) {
//             registerAnimation(e)
//         }
//     }
// }

const initSwiper = (carousel) => {
    if (winW < 992 && swiper == undefined) {
        let prop = {}

        if (carousel === '.why-it-matters-carousel') {
            prop.spaceBetween = 0;
        }
        swiper = new Swiper(carousel, {
            modules: [Pagination, Autoplay],
            slidesPerView: 1.1,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: false
            },
            autoplay: {
                delay: 3000,
            },
            on: {
                afterInit: function () {
                    setHeight('.fs-carousel__card')
                },
                resize: function() {
                    setHeight('.fs-carousel__card')
                },
                destroy: function() {
                    setHeight('.fs-carousel__card')
                }
            },
            ...prop
        });
    } else if (winW >= 992 && swiper != undefined) {
        swiper.destroy();
        swiper = undefined;
        jQuery('.swiper-wrapper').removeAttr('style');
        jQuery('.swiper-slide').removeAttr('style');
    }
}

const setHeight = (el) => {
    $(el).removeAttr('style');
    let highest = -9999;
    $(el).each(function () {
        if ($(this).outerHeight() > highest) {
            highest = $(this).outerHeight();
        }
    })

    $(el).css('height', highest)
}

const initReadMore = () => {
    $('.read-btn').on('click', function (e) {
        e.preventDefault();
        if ($('.hidden').is(':visible')) {
            $(".hidden").animate({ height: 'toggle', opacity: 0 }, 200);
            $('.read-btn').html('Read more');
        } else {
            // setTimeout(() => {
            //     $('span.hidden').css('display', 'inline');
            // }, 100)
            $('.hidden').animate({ height: 'toggle', opacity: 1 }, 200);
            $('.read-btn').html('Read less');
        }
    })

    $(window).on('resize', function () {
        if (winW > 991) {
            $('.hidden').removeAttr('style');
            $('.read-btn').html('Read more');
        }
    });
}

const initAccordion = () => {
    $('.toggle').on('click', function () {
        $(this).addClass('triggered');
        $('.toggle').each(function () {
            if ($(this).hasClass('triggered')) {
                $(this).removeClass('triggered');
                if ($(this).parent().parent().parent().index() === openedAccordionIndex) {
                    openedAccordionIndex = -1;
                    $(this).children('.icon-wrapper').children('i').removeClass('fa-minus').addClass('fa-plus');
                } else {
                    $('.accordion-content').eq(openedAccordionIndex).children().first().children().children().children().children().removeClass('fa-minus').addClass('fa-plus');
                    $(this).children('.icon-wrapper').children('i').removeClass('fa-plus').addClass('fa-minus');
                    openedAccordionIndex = $(this).parent().parent().parent().index();
                    setTimeout(() => {
                        $('html, body').animate({
                            scrollTop: $(this).offset().top - 75
                        }, 400);
                    }, 400)
                }

                return;
            }
        })
    })

    let totalAccordion = $('.accordion-content').length + 1;

    while (totalAccordion--) {
        $('#collapse-' + totalAccordion).html($('#pills-' + totalAccordion).html())
    }
}

const initPage = () => {
    if ($('.fs-card-carousel').length > 0) {
        if(winW > 991) {
            setHeight('.fs-carousel__card')
        }
        initSwiper('.fs-card-carousel')

        $(window).on('resize', function () {
            initSwiper('.fs-card-carousel')
            if(winW > 991) {
                setHeight('.fs-carousel__card')
            }
        });
    }

    if ($('.accordion').length > 0) {
        initAccordion();
    }

    if ($('.pricing').length > 0) {
        if (winW > 991) {
            setHeight('.package');
        }

        $('.form-enquiry #business-type').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
            if(clickedIndex === 12) {
                $('.form-enquiry #other-business-type').removeAttr('disabled')
            }else {
                $('.form-enquiry #other-business-type').attr('disabled', true)
            }
        })

        $('.open-enquiry').magnificPopup({
            closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"><i class="ion-close-round mfp-close"></i></button>',
            fixedContentPos: true,
            overflowY: 'scroll',
            removalDelay: 500,
            callbacks: {
                beforeOpen: function() {
                    this.st.mainClass = 'mfp-fade-in';
                },
                open: function() {
                    let mp = $.magnificPopup.instance;
                    let el = $(mp.currItem.el[0]);
                    $('#enquiry .selected-plan span').html(el.data('package'));
                    $('.form-enquiry input[name="plan"]').val(el.data('package'));
                },
                close: function() {
                    $('.form-enquiry')[0].reset();
                    $('.form-enquiry .selectpicker').selectpicker('val', '');
                    enquiryRecaptchaValidate = false
                }
            }
        });

        $(window).on('resize', function () {
            if (winW > 991) {
                setHeight('.package');
            } else {
                $('.package').removeAttr('style');
            }
        });

        $('.form-enquiry').submit(async function(e) {
            $(this).children().children('.form-group').removeClass('invalid');
            e.preventDefault();
            let formdata  = $(this).serializeArray()
            let valid = validateField(formdata);

            if(valid.length) {
                valid.map(index => {
                    $(this).children().children('.form-group').eq(index).addClass('invalid')
                });
            }else if(!enquiryRecaptchaValidate) {
                $(this).children().children('div').children('.form-group').children('.g-recaptcha').siblings('.error-msg').show()
            }else {
                $.magnificPopup.open({
                    items: {
                        src: $('#form-result'),
                    },
                    showCloseBtn: false,
                    closeOnBgClick: false,
                    removalDelay: 500,
                    callbacks: {
                        beforeOpen: function() {
                            $('#form-result').html(loading)
                            this.st.mainClass = 'mfp-fade-in';
                        }
                    }
                })

                try {
                    const response = await axios({
                        method: 'POST',
                        url: 'https://production.flex-solver.app/template/website/enquiry_mailer',
                        data: formdata
                    })

                    if ($.magnificPopup.instance.isOpen) {
                        if (response.data.status === 200) {
                            $('#form-result').html(success)
                        } else {
                            axiosErrorHandler(response.message)
                        }
                    }
                } catch (err) {
                    axiosErrorHandler(err)
                }

                $('#form-result .fs-btn').on('click', function() {
                    $.magnificPopup.close();
                })


                // $.ajax( {
                //     url: 'enquiry_mailer.php',
                //     type: 'POST',
                //     data: new FormData( this ),
                //     processData: false,
                //     contentType: false,
                //     success: function(data) {
                //         let response = JSON.parse(data)
                //         setTimeout(() => {
                //             if($.magnificPopup.instance.isOpen) {
                //                 if(response.status === 200) {
                //                     $('#form-result').html(success)
                //                 }else {
                //                     $('#form-result').html(error)
                //                     $('#form-result .error').html(response.message)
                //                 }
                //                 $('#form-result .fs-btn').on('click', function() {
                //                     $.magnificPopup.close();
                //                 })
                //             }
                //         }, 510)
                //     },
                // } );

                // $('.form-enquiry')[0].reset();
                // $('.form-enquiry .selectpicker').selectpicker('val', '');
                // enquiryRecaptchaValidate = false
                // grecaptcha.reset();
                // $.magnificPopup.close();

                // setTimeout(() => {
                //     $.magnificPopup.open({
                //         items: {
                //             src: $('#form-result'),
                //         },
                //         showCloseBtn: false,
                //         closeOnBgClick: false,
                //         removalDelay: 500,
                //         callbacks: {
                //             beforeOpen: function() {
                //                 $('#form-result').html(loading)
                //                 this.st.mainClass = 'mfp-fade-in';
                //             }
                //         }
                //     })
                // }, 510)
            }
        })
    }

    if ($('.read-btn').length) {
        initReadMore();
    }

    if ($('.why-it-matters-carousel').length) {
        initSwiper('.why-it-matters-carousel')

        $(window).on('resize', function () {
            initSwiper('.why-it-matters-carousel');
        });
    }

    if ($('.history').length) {

        if (winW >= 768) {
            setHeight('.history .fs-card');
        }

        $(window).on('resize', function () {
            if (winW >= 768) {
                setHeight('.history .fs-card');
            } else {
                $('.history .fs-card').removeAttr('style');
            }
        });
    }

    if($('#fsv2-emenu-page').length) {
        $('.open-calculate-price').magnificPopup({
            closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"><i class="ion-close-round mfp-close"></i></button>',
            removalDelay: 500,
            callbacks: {
                beforeOpen: function() {
                    this.st.mainClass = 'mfp-fade-in';
                },
                open: function() {
                    let mp = $.magnificPopup.instance;
                    let el = $(mp.currItem.el[0]);
                    $('#calculate-price .fee').html(el.data('fee'));
                }
            }
        });
    }

    if ($('#fsv2-careers-page').length) {
        initCareersPage();
    }

    if ($('#fsv2-job-description-page').length) {
        initJobDescriptionPage();
    }
}

$(document).ready(function () {
    window.FontAwesomeConfig = {
        searchPseudoElements: true
    }

    winW = $(window).outerWidth();

    $('#back-to-top').on('click', function () {
        backToTop();
    })

    $('.scroll-nav').on('click', function(e){
        e.preventDefault();
        let redirect = $(this).data('redirect');
        let scroll;
        if($('#fsv2-people-hub-page').length) {
            if(winW > 991) {
                scroll = $(redirect).offset().top - 100;
            }else {
                scroll = $(redirect).offset().top - 150;
            }
        } else if(redirect === '.fs-form') {
            if(winW > 991) {
                scroll = $(redirect).offset().top - 100;
            }else {
                scroll = $(redirect).offset().top - 150;
            }
        } else if(redirect === '.fs-form.white') {
            if(winW > 991) {
                scroll = $(redirect).offset().top - 100;
            }else {
                scroll = $(redirect).offset().top - 75;
            }
        }else if(redirect === '.product-deliver' && winW < 992){
            scroll = $('.product-deliver-mobile').offset().top - 75;
        }else {
            scroll = $(redirect).offset().top - 75;
        }
        $('html, body').animate({
            scrollTop: scroll
        }, 1000);
    })

    $('#request-demo').on('change', function() {
        if($(this).is(':checked')) {
            $('.contact-product').selectpicker('val', '');
            $('.product-select').removeClass('invalid').slideDown();
        }else {
            $('.product-select').removeClass('invalid').slideUp();
        }
    })

    $('.contact-business-type').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        if(clickedIndex === 12) {
            $('.other-business-input').slideDown();
        }else {
            $('.other-business-input').slideUp();
        }
    })

    $('.form-contact').submit(async function(e) {
        $(this).children('.form-group').removeClass('invalid');
        e.preventDefault();
        let formdata  = $(this).serializeArray()
        let valid = validateField(formdata);

        if(valid.length) {
            valid.map(index => $(this).children('.form-group').eq(index).addClass('invalid'));
        }else if(!recaptchaValidate) {
            $(this).children('google-recaptcha').children('.form-group').children('.g-recaptcha').siblings().show()
        }else {
            $.magnificPopup.open({
                items: {
                    src: $('#form-result'),
                },
                showCloseBtn: false,
                closeOnBgClick: false,
                removalDelay: 500,
                callbacks: {
                    beforeOpen: function() {
                        $('#form-result').html(loading)
                        this.st.mainClass = 'mfp-fade-in';
                    }
                }
            })

            try {
                const response = await axios({
                    method: 'POST',
                    url: 'https://production.flex-solver.app/template/website/contact_mailer',
                    data: formdata
                })

                if ($.magnificPopup.instance.isOpen) {
                    if (response.data.status === 200) {
                        $('#form-result').html(success)
                    } else {
                        axiosErrorHandler(response.message)
                    }
                }
            } catch (err) {
                axiosErrorHandler(err)
            }


            $('#form-result .fs-btn').on('click', function() {
                $.magnificPopup.close();
            })

            // $.ajax( {
            //     url: 'contact_mailer.php',
            //     type: 'GET',
            //     data: new FormData( this ),
            //     processData: false,
            //     contentType: false,
            //     success: function(data) {
            //         let response = JSON.parse(data)
            //         if($.magnificPopup.instance.isOpen) {
            //             if(response.status === 200) {
            //                 $('#form-result').html(success)
            //             }else {
            //                 $('#form-result').html(error)
            //                 $('#form-result .error').html(response.message)
            //             }
            //             $('#form-result .fs-btn').on('click', function() {
            //                 $.magnificPopup.close();
            //             })
            //         }
            //     },
            // } );

            // $('.form-contact')[0].reset();
            // $('.form-contact .selectpicker').selectpicker('val', '');
            // $('.contact-product').hide();
            // recaptchaValidate = false;
            // grecaptcha.reset();
        }
    })

    $('form input, form textarea').on('blur', function() {
        $(this).parent().removeClass('invalid');
    })

    $('form .selectpicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        $(this).parent().parent().removeClass('invalid');
    })

    var moreDetailsDevice = new Swiper(".more-details-device", {
        modules: [EffectFade, Autoplay],
        spaceBetween: 30,
        effect: "fade",
        fadeEffect: {
            crossFade: true
        },
        autoplay: {
            delay: 3000,
        },
    });

    navBarRegisterHover();
    canvasNavBarSetup();
    pageScrolled();

    $(window).on('load', function() {
        initPage();
    });


    $('.cover').fadeOut(1000, function() {
        $(this).css('opacity', 0)
    });
    animateStats();

    // animate homepage
    // let _e = [
    //     '#fs-hero',
    //     '#about-us',
    //     '#customer-spotlight',
    //     '#uniqly-flexsolver',
    //     '#our-achievements'
    // ];
    // animatePage('#fsv2-home-page', _e);

    // // animate emenu page
    // _e = [
    //     '#fs-hero',
    //     '#emenu-whatis',
    //     '#emenu-orderingprocess',
    //     '#emenu-package',
    //     '#emenu-features',
    //     '#emenu-whyworkwithus'
    // ];
    // animatePage('#fsv2-emenu-page', _e);

    $(window).on('resize', function () {
        winW = $(window).outerWidth();
    });
})

const axiosErrorHandler = (err) => {
    $('#form-result').html(error)
    $('#form-result .error').html(err.message)

    $('.form-contact')[0].reset();
    $('.form-contact .selectpicker').selectpicker('val', '');
    $('.contact-product').hide();

    recaptchaValidate = false;
    enquiryRecaptchaValidate = false

    setTimeout(() => {
        grecaptcha.reset();
    })
}