class TickBullet extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        let colorTick,colorCircle;

        switch(this.attributes.color.value) {
            case 'purple':
                colorCircle = '#D7B7FF';
                colorTick = '#812BEA';
                break;
            case 'light-blue':
                colorCircle = '#BBD0FF';
                colorTick = '#3776FF';
                break;
            case 'orange':
                colorCircle = '#FECEB1';
                colorTick = '#FF7B2B';
                break;
            case 'secondary':
                colorCircle = '#AEE8E0';
                colorTick = '#00BFA5';
                break;
            default: 
                colorCircle = '#BBD0FF';
                colorTick = '#3776FF';
                break;
        }

        this.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g id="tick" transform="translate(-9.2 -13.2)">
                    <path id="Path_1" data-name="Path 1"
                        d="M29.464,29.072a.766.766,0,1,0-1.133,1.03l3.888,4.223a.77.77,0,0,0,.541.257h.026a.7.7,0,0,0,.541-.232l9.836-9.81a.747.747,0,0,0,0-1.081.769.769,0,0,0-1.081,0L32.811,32.7Z"
                        transform="translate(-14.554 -7.453)" fill="${colorTick}"/>
                    <path id="Path_2" data-name="Path 2"
                        d="M28.446,22.446a.74.74,0,0,0-.754.754A8.492,8.492,0,1,1,19.2,14.708a.754.754,0,0,0,0-1.508,10,10,0,1,0,10,10A.74.74,0,0,0,28.446,22.446Z"
                        transform="translate(0 0)" fill="${colorCircle}"/>
                </g>
            </svg>
        `
    }
}

customElements.define('tick-bullet', TickBullet);