class DesktopNavContent extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = `
        <div class="fs-about-us__navcontent">
            <div class="inner-content">
                <div class="row w-100">
                    <div class="col-4 about-us__nav">
                        <div class="fs-paragraph__left-border primary">
                            <ul class="page-list text-primary">
                                <li><a href="our-story.html">Our Story</a></li>
                                <li><a href="careers.html">Careers</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="fs-products__navcontent">
            <div class="inner-content">
                <div class="row w-75">
                    <div class="col-4">
                        <div class="fs-paragraph__left-border secondary">
                            <a href="retail-fnb-hub.html">
                                <div>
                                    <img class="fs-hub__title mb-2" src="./static/general/retail_hub.svg"/>
                                    <p class="text-secondary font-weight-500 mb-0">Flex Retail/F&B Hub</p>
                                </div>
                            </a>
                            <div>
                                <ul class="page-list text-secondary">
                                    <li><a href="emenu.html">eMenu</a></li>
                                    <li><a href="pos.html">Point of Sales</a></li>
                                    <li><a href="ecommerce.html">eCommerce</a></li>
                                    <li><a href="scm.html">Supply Chain Management</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="fs-paragraph__left-border orange">
                            <a href="people-hub.html">
                                <div>
                                    <img class="fs-hub__title mb-2" src="./static/general/people_hub.svg"/>
                                    <p class="text-orange font-weight-500 mb-0">Flex People Hub</p>
                                </div>
                            </a>
                            <div>
                                <ul class="page-list text-orange">
                                    <li><a href="hrms.html">HR Management System (HRMS)</a></li>
                                    <li><a href="voffice.html">V-Office</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="fs-paragraph__left-border purple">
                            <a href="experience-hub.html">
                                <div>
                                    <img class="fs-hub__title mb-2" src="./static/general/brand_hub.svg"/>
                                    <p class="text-purple font-weight-500 mb-0">Flex Experience Hub</p>
                                </div>
                            </a>
                            <div>
                                <ul class="page-list text-purple">
                                    <li><a href="customer-and-design.html">Customer and Design</a></li>
                                    <li><a href="business-process.html">Business Process Redesign</a></li>
                                    <li><a href="grant-consulting.html">Grant Consulting</a></li>
                                    <li class="disabled"><a>Experience Management (Coming Soon)</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `
    }
}

customElements.define('desktop-nav-content', DesktopNavContent);