class ButtonArrow extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        let color = '#fff';

        if(this.attributes.color){
            color = this.attributes.color.value;
        }

        this.innerHTML = `
            <svg class="ml-3" xmlns="http://www.w3.org/2000/svg" width="20" height="9.688" viewBox="0 0 20 9.688">
                <path id="ico_right-arrow_short"
                    d="M19.771,136.291h0l-4.082-4.062a.781.781,0,0,0-1.1,1.107l2.741,2.727H.781a.781.781,0,0,0,0,1.563H17.326l-2.741,2.727a.781.781,0,0,0,1.1,1.107L19.77,137.4h0A.782.782,0,0,0,19.771,136.291Z"
                    transform="translate(0 -132)" fill="${color}" />
            </svg>
        `
    }
}

customElements.define('button-arrow', ButtonArrow);