class OffCanvasBar extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
		let productPages = [
			'/emenu.html',
			'/pos.html',
			'/ecommerce.html',
			'/scm.html',
			'/hrms.html',
			'/voffice.html',
			'/customer-and-design.html',
			'/business-process.html',
			'/grant-consulting.html'
		]
		const isHome = window.location.pathname === '/';
		const isProduct = productPages.includes(window.location.pathname);

        this.innerHTML = `
			<div id="fs-off_canvas__bar">
				<div class="d-flex justify-content-between mb-3">
					<img style="width:160px" class="contain" src="./static/flex-solver.svg"/>
					<button class="navbar-toggler active" id="fs-inner__toggler" type="button" aria-expanded="false"
						aria-label="Toggle navigation">
						<i></i>
					</button>
				</div>
				<ul class="d-flex flex-column align-items-start mb-3">
					<li class="fs-off_canvas__item ${ isHome ? 'active' : '' }">
						<a class="nav-link" href="index.html" data-nav-section="home">Home</a>
					</li>
					<li class="fs-off_canvas_item__dropdown ${ isProduct ? 'active' : '' }">
						<span>Products</span>	
						<div class="dropdown__content">
							<div>
								<a href="retail-fnb-hub.html" class="no-underline d-flex align-items-center">
									<div class="logo-container">
										<img class="fs-hub__title small mr-3" src="./static/general/retail_hub.svg" />
									</div>
									<p class="mb-0 text-secondary font-weight-500">Flex Retail/F&B Hub</p>
								</a>
							</div>	
							<ul class="page-list secondary">
								<li><a class="link" href="emenu.html">eMenu</a></li>
								<li><a class="link" href="pos.html">Point of Sales</a></li>
								<li><a class="link" href="ecommerce.html">eCommerce</a></li>
								<li><a class="link" href="scm.html">Supply Chain Management</a></li>
							</ul>
													
							<div class="mt-4">
								<a href="people-hub.html" class="no-underline d-flex align-items-center ">
									<div class="logo-container">
										<img class="fs-hub__title small mr-3" src="./static/general/people_hub.svg" />
									</div>
									<p class="mb-0 text-orange font-weight-500">Flex People Hub</p>
								</a>
							</div>
							<ul class="page-list orange">
								<li><a class="link" href="hrms.html">HRMS</a></li>
								<li><a class="link" href="voffice.html">V-Office</a></li>
							</ul>

							<div class="mt-4">
								<a href="experience-hub.html" class="no-underline d-flex align-items-center ">
									<div class="logo-container">
										<img class="fs-hub__title small mr-3" src="./static/general/brand_hub.svg" />
									</div>
									<p class="mb-0 text-purple font-weight-500">Flex Experience Hub</p>
								</a>
							</div>
							<ul class="page-list purple">
								<li><a class="link" href="customer-and-design.html">Customer and Design</a></li>
								<li><a class="link" href="business-process.html">Business Process Redesign</a></li>
								<li><a class="link" href="grant-consulting.html">Grant Consulting</a></li>
								<li class="disabled"><a class="link">Experience Management (Coming Soon)</a></li>
							</ul>
						</div>
					</li>
					<li class="fs-off_canvas_item__dropdown">
						<span>About us</span>
						<div class="dropdown__content">
							<ul class="page-list">
								<li><a class="link" href="our-story.html">Our Story</a></li>
								<li><a class="link" href="careers.html">Careers</a></li>
							</ul>
						</div>
					</li>
				</ul>
				<a href="contact-us.html">
				<button class="btn btn-primary fs-btn btn__tall btn__air text-uppercase btn-smaller__text">
					Speak To Us
				</button>
				</a>
			</div>
    `;
    }
}

customElements.define('off-canvas-bar', OffCanvasBar);
