const validateField = (formdata) => {
	let error = [];

	for (const [i, field] of formdata.entries()) {
		switch(field.name) {
			case 'contact':
				let contactRegex = /^[0-9]{8}$/;
				if(field.value === '' || !contactRegex.test(field.value)){
					error.push(i)
				}
				break; 
			case 'email':
				let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if(field.value === '' || !emailRegex.test(field.value)){
					error.push(i)
				}
				break;
			case 'other-business-type':
				let businessType = formdata.filter(field => field.name === 'business-type')[0].value.toLowerCase();
				if(field.value === '' && businessType === 'other'){
					error.push(i)
				}
				break;
			case 'product':
				let requestDemo = formdata.filter(field => field.name === 'request-demo');
				if(requestDemo.length && field.value === '') {
					error.push(i)
				}
				break;
			case 'g-recaptcha-response':
				break;
			default:
				if(field.value === ''){
					error.push(i)
				} 
				break;
		}
	}

	return error;
}

export { validateField };