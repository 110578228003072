class EnquiryForm extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = `
		<div id="enquiry" class="white-popup mfp-with-anim mfp-hide">
			<h3 class="mb-3">Enquiry</h3>
			<p class="font-weight-500 selected-plan">Selected plan: <span></span></p>
			<p>Leave your contact details and we will be in touch within 48 working hours</p>
			<form class="form-enquiry">
				<div class="row">
					<div class="col-lg-6 form-group">
						<input type="text" name="fullname" class="form-control" placeholder="*Full Name">
						<span class="error-msg text-danger">Please enter a name.</span>
					</div>
					<div class="col-lg-6 form-group">
						<input type="tel" name="email" class="form-control" placeholder="*Email">
						<span class="error-msg text-danger">Please enter valid email.</span>
					</div>
					<div class="col-lg-6 form-group">
						<input type="text" name="organisation" class="form-control" placeholder="*Organisation">
						<span class="error-msg text-danger">Please enter an organisation.</span>
					</div>
					<div class="col-lg-6 form-group">
						<input type="text" name="role" class="form-control" placeholder="*Role">
						<span class="error-msg text-danger">Please enter a role.</span>
					</div>
					<div class="col-lg-6 form-group">
						<select name="business-type" id="business-type" class="selectpicker form-control" title="*Business Type">
							<option>Banking and Finance</option>
							<option>Biomedical</option>
							<option>Construction</option>
							<option>Education</option>
							<option>Food and Beverage</option>
							<option>Healthcare</option>
							<option>Info-Commmunications and Media</option>
							<option>Logistics</option>
							<option>Manufacturing</option>
							<option>Retail</option>
							<option>Tourism</option>
							<option>Other</option>
						</select>
						<span class="error-msg text-danger">Please select a Business Type</span>
					</div>
					<div class="col-lg-6 form-group">
						<input type="text" name="other-business-type" id="other-business-type" class="form-control" placeholder="Describe other business type" disabled>
						<span class="error-msg text-danger">Please enter a business type.</span>
					</div>
					<div class="col-sm-12 form-group">
						<textarea name="intro" class="form-control" rows="3" placeholder='*Tell us more about your business'></textarea>
						<span class="error-msg text-danger">Please tell us more about your business.</span>
					</div>
					<div class="col-sm-12 d-lg-flex justify-content-between align-items-center">
						<div class="form-group">
							<div class="g-recaptcha" data-sitekey="6Lf5Db0UAAAAAGoIT4bpLriHXgF0cWNn3eclao_p" data-callback="enquiryGrecaptchaRes" data-expired-callback="enquiryGrecaptchaExpired"></div>
							<span class="error-msg text-danger">Please validate.</span>
							<input type="text" name="plan" class="d-none">
						</div>
						<div class="submit-button">
							<button class="btn btn-primary fs-btn btn__tall btn-smaller__text btn__air" style="min-width: 200px;">
								<span class="text-uppercase">Submit</span>
							</button>
						</div>
					</div>
					
				</div>
			</form>
		</div>
        `
    }
}

customElements.define('enquiry-form', EnquiryForm);