class NavHeader extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        let productPages = [
            '/retailhub.html',
            '/peoplehub.html',
            '/identityhub.html'
        ]

        let solutionPages = [

        ]

        let customerPages = [

        ]

        const isHome = window.location.pathname === '/';
        const isProduct = productPages.includes(window.location.pathname);
        const isSolution = solutionPages.includes(window.location.pathname);
        const isCustomer = customerPages.includes(window.location.pathname);

        this.innerHTML = `
        <nav id="fs-main__nav" class="navbar navbar-expand-lg navbar-light">
            <div class="container">
                <a class="navbar-brand" href="index.html"><img src="static/flex-solver.svg"></a>
                <button class="navbar-toggler" id="fs-canvas__toggler" type="button" aria-expanded="false"
                    aria-label="Toggle navigation">
                    <i></i>
                </button>

                <div class="collapse navbar-collapse" id="fs-top__navbar">
                    <ul class="navbar-nav ml-auto d-flex align-items-center">
                        <li class="fs-nav__item font-weight-600 ${ isHome ? 'active' : '' }">
                            <a class="nav-link" href="index.html" data-nav-section="home">Home</a>
                        </li>
                        <li class="nav-item text-uppercase font-weight-600">
                            <div class="nav-link fs-dropdown ${ isProduct ? 'active' : '' }" id="fs-products__links">
                                <span>Products</span>
                            </div>
                        </li>
                        <li class="nav-item text-uppercase font-weight-600">
                            <div class="nav-link fs-dropdown ${ isProduct ? 'active' : '' }" id="fs-about-us__links">
                                <span>About us</span>
                            </div>
                        </li>
                        
                        <li class="nav-item">
                            <a href="contact-us.html">
                                <button id="contact-us"
                                    class="btn btn-primary fs-btn btn-smaller__text btn__air btn__small text-uppercase">
                                    Speak to Us
                                </button>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        
        `
    }
}

customElements.define('nav-header', NavHeader);