import moment from 'moment';
import "magnific-popup";
import { validateField } from './validation';

let loading = `
<div class="d-flex flex-column align-items-center">
    <h3>Submitting...</h3>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 30px auto;display: block;shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#3776ff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8264462809917356s" values="0 50 50;360 50 50" keyTimes="0;1"/>
        </circle>
    </svg>
</div>`;

let success = `
<div class="d-flex flex-column align-items-center">
    <h3>Submitted</h3>
    <i class="fas fa-check-circle"></i>
    <p>Your request has been submitted. We will get back to you shortly.</p>
    <button class="btn btn-primary fs-btn btn__tall btn-smaller__text btn__air">
        <span class="text-uppercase">Done</span>
    </button>
</div>`;

let error = `
<div class="d-flex flex-column align-items-center">
    <h3>Opps, something's wrong!</h3>
    <i class="fas fa-times-circle text-danger"></i>
    <p class="error"></p>
    <button class="btn btn-primary fs-btn btn__tall btn-smaller__text btn__air">
        <span class="text-uppercase">Back</span>
    </button>
</div>
`;

let jobList;
let recaptchaValidate = false;

const jobGrecaptchaRes = () => {
    $('.form-application .g-recaptcha').siblings().hide();
    const res = grecaptcha.getResponse()

    if(res.length) {
        recaptchaValidate = true;
    }
}

window.jobGrecaptchaRes = jobGrecaptchaRes;

const jobGrecaptchaExpired = () => {
    recaptchaValidate = false;
}

window.jobGrecaptchaExpired = jobGrecaptchaExpired;

const getJobList = async () => {
    let data = await fetch(`./static/jobs.json?t=${new Date().getTime()}`)
        .then(response => response.json());

    const list = data.filter(job => moment(job.date, 'DD-MM-YYYY').add(3, 'months').isSameOrAfter(moment(), 'day'))

    return list;
}

const initCareersPage = () => {
    let file, slideTextUp, moveTextPosition, y;

    if ($(window).outerWidth() > 767) {
        y = '-52px';
     } else {
        y = '-39px';
     }
    
    slideTextUp = setInterval(() => {
        $('.scroll-text')
        .addClass('can-transition')
        .css('transform', `translateY(${y})`)
    }, 5000);

    moveTextPosition = setInterval(() => {
        $('.scroll-text').children('span').eq(2).after($('.scroll-text').children('span').eq(0));
        $('.scroll-text').removeClass('can-transition').css('transform', 'translateY(0)')
    }, 5400);

	$('.open-application-form').magnificPopup({
		closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"><i class="ion-close-round mfp-close"></i></button>',
        fixedContentPos: true,
        overflowY: 'scroll',
        removalDelay: 500,
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = 'mfp-fade-in';
            },
            close: function() {
                $('.form-application')[0].reset();
                $(".filename").removeClass('uploaded').html('');
                recaptchaValidate = false;
            }
        }
    });

	$('.open-mobile-filter').magnificPopup({
		closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"><i class="ion-close-round mfp-close"></i></button>',
        fixedContentPos: true,
        overflowY: 'scroll',
        removalDelay: 500,
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = 'mfp-fade-in mfp-no-padd';
            },
        }
    });

	$('.file-upload').on('click', function(e) {
		e.preventDefault();			
		$('#resume').trigger('click');
	})

    $('#agreement').on('change', function() {
        $('#agreement').parent().removeClass('invalid');
    })

	$('#resume').on('change', function() {
        $('.resume-upload').removeClass('invalid')
        file = this.files;
		$(".filename").addClass('uploaded').html(file[0].name);
	})

    $('.department, .work-type, .department-mobile, .work-type-mobile').selectpicker({
        showContent: false,
        selectedTextFormat: 'count',
    })

    $('.department, .work-type, .department-mobile, .work-type-mobile').on('loaded.bs.select', function () {
        if($(this).hasClass('show-tick')) {
            $(this).removeClass('show-tick')
        }
    })

    $('.department, .work-type').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
		let selected = $(this).selectpicker('val');
		let select = $(this).hasClass('department') ? 'department' : 'work-type';

        if ($(this).selectpicker('val').length > 1) {          
            let index = $(this).selectpicker('val').indexOf('All');
			
            if (index > -1) {
                if (clickedIndex === 0) {
                    $(this).selectpicker('val', ['All']);
                    $(this).selectpicker('toggle');
                } else {
                    selected.splice(index, 1);
                    $(this).selectpicker('val', selected);
                }
            }
        } else {
            if (clickedIndex === 0) {
                $(this).selectpicker('toggle');
            }
        }

		if($(window).outerWidth() > 991) {
			$(`.selectpicker.${select}-mobile`).selectpicker('val', selected);
		}

        generateFilter();
    });

	$('.department-mobile, .work-type-mobile').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
		let selected = $(this).selectpicker('val');
		let select = $(this).hasClass('department-mobile') ? 'department' : 'work-type';

        if ($(this).selectpicker('val').length > 1) {
            let index = $(this).selectpicker('val').indexOf('All');
            if (index > -1) {
                if (clickedIndex === 0) {
                    $(this).selectpicker('val', ['All']);
                    $(this).selectpicker('toggle');
                } else {
                    selected.splice(index, 1);
                    $(this).selectpicker('val', selected);
                }
            }
        } else {
            if (clickedIndex === 0) {
                $(this).selectpicker('toggle');
            }
        }
    });

	$('.apply-filter').on('click', function(e){
		$(`.selectpicker.department`).selectpicker('val', $('.department-mobile').selectpicker('val'));
		$(`.selectpicker.work-type`).selectpicker('val', $('.work-type-mobile').selectpicker('val'));

		$.magnificPopup.close();
	})

	$('.clear-filter').on('click', function(e) {
        if($(window).outerWidth() > 991) {
			$('.department').selectpicker('val', []);
            $('.work-type').selectpicker('val', []);
		} else {
            $('.department-mobile').selectpicker('val', []);
            $('.work-type-mobile').selectpicker('val', []);
        }
	})

    $('.form-application').submit(function(e) {
        let isValid = true;
        $(this).children().children('.form-group').removeClass('invalid');
        e.preventDefault();

        let formdata  = $(this).serializeArray()
        let valid = validateField(formdata);
        if(valid.length) {
            isValid = false;
            valid.map(index => {
                $(this).children().children('.form-group').eq(index).addClass('invalid')
            });
        } 
        
        if(!file || !file.length) {
            isValid = false;
            $('.resume-upload').addClass('invalid');
        }

        if(!$('#agreement').is(':checked')) {
            isValid = false;
            $('#agreement').parent().addClass('invalid');
        }

        if(!recaptchaValidate) {
            isValid = false;
            $('.form-application .g-recaptcha').siblings().show();
        }

        if(isValid) {
            $.ajax( {
                url: 'job_mailer.php',
                type: 'POST',
                data: new FormData( this ),
                processData: false,
                contentType: false,
                success: function(data) {
                    let response = JSON.parse(data)
                    if($.magnificPopup.instance.isOpen) {
                        if(response.status === 200) {
                            $('#form-result').html(success)
                        }else {
                            $('#form-result').html(error)
                            $('#form-result .error').html(response.message)
                        }
                        $('#form-result .fs-btn').on('click', function() {
                            $.magnificPopup.close();
                        })
                    }                   
                },
            } );

            $('.form-application')[0].reset();
            $(".filename").removeClass('uploaded').html('');
            recaptchaValidate = false;
            grecaptcha.reset();
            
            $.magnificPopup.close();
            setTimeout(() => {
                $.magnificPopup.open({
                    items: {
                        src: $('#form-result'),            
                    },
                    showCloseBtn: false,
                    closeOnBgClick: false,
                    removalDelay: 500,
                    callbacks: {
                        beforeOpen: function() {
                            $('#form-result').html(loading)
                            this.st.mainClass = 'mfp-fade-in';
                        }
                    }
                })
            }, 510)
        }
    })

    $('.job-search input').on('keyup', function (e) {
        generateFilteredJobList();
    })

    getJobList().then(data => {
        jobList = data;
        generateJobListHTML(data, true)
    });

    $(window).on('resize', function () {
        clearInterval(slideTextUp);
        clearInterval(moveTextPosition);
        if ($(window).outerWidth() > 767) {
            y = '-52px';
        } else {
            y = '-39px';
        }

        slideTextUp = setInterval(() => {
            $('.scroll-text')
            .addClass('can-transition')
            .css('transform', `translateY(${y})`)
        }, 3000);
    
        moveTextPosition = setInterval(() => {
            $('.scroll-text').children('span').eq(2).after($('.scroll-text').children('span').eq(0));
            $('.scroll-text').removeClass('can-transition').css('transform', 'translateY(0)')
        }, 3400);
    });
}

const generateFilter = () => {
    let html = '';
    let departments = $('.department').selectpicker('val');
    let workTypes = $('.work-type').selectpicker('val');

    let jobFilter = departments.concat(workTypes)

    for (let filter of jobFilter) {
        if(filter !== 'All') {
            html += '<div class="selected-box">';
            html += `<p class="mb-0 mr-3">${filter}</p>`;
            html += '<i class="ion-close"></i>';
            html += '</div>';
        }
    }

    $('.selected-filter').html(html);

    generateFilteredJobList();

    $('.selected-box .ion-close').on('click', function (e) {
        let selected = $(this).siblings('p').html();
        if (departments.includes(selected)) {
            departments = departments.filter(department => department !== selected);
			if($(window).outerWidth() < 992) {
				$('.department-mobile').selectpicker('val', departments);
			}

			$('.department').selectpicker('val', departments);
            
        } else if (workTypes.includes(selected)) {
            workTypes = workTypes.filter(type => type !== selected);
			if($(window).outerWidth() < 992) {
				$('.work-type-mobile').selectpicker('val', workTypes);
			}

			$('.work-type').selectpicker('val', workTypes);
        }
    })
}

const generateFilteredJobList = () => {
    let searchTerm = $('.job-search input').val() ? $('.job-search input').val() : '';
    let departments = $('.department').selectpicker('val');
    let workTypes = $('.work-type').selectpicker('val');
    let filteredJobList = jobList.map(job => job);

    if (departments.length && !departments.includes('All')) {
        filteredJobList = filteredJobList.filter(job => departments.includes(job.department))
    }

    if (workTypes.length && !workTypes.includes('All')) {
        filteredJobList = filteredJobList.filter(job => workTypes.includes(job.workType))
    }

    if (searchTerm.length) {
        filteredJobList = filteredJobList.filter(job => job.title.toUpperCase().indexOf(searchTerm.toUpperCase()) > -1)
    }

    generateJobListHTML(filteredJobList, false)
}

const generateJobListHTML = (data, isFirstLoad) => {
    let html = "";

    if(!data.length && isFirstLoad) {
        $('.text-content').html('<p class="empty-job-list-content text-center position-relative">We do not currently have any open positions. Regardless, we would like to get to <br class="d-none d-lg-block">know you and we might just be the perfect match!</p>');
        $('.filter, .job-list, .submit-resume').hide();
        return;
    }

    for (let job of data) {
        html += `<a class="no-underline" href="job-description.html?id=${job.id}">`;
        html += '<div class="job">';
        html += `<p class="mb-1">Posted on ${moment(job.date).format('DD MMM YYYY')}</p>`;
        html += `<h3 class="mb-4">${job.title}</h3>`;
        html += `<p class="mb-0">${job.department} - ${job.workType} - ${job.experience} Year${job.experience > 1 ? 's' : ''}</p>`;
        html += '</div>';
        html += '</a>';
    }
   
    $('.job-list').html(html);
    
    $('.search-count').html(`Showing <strong>${data.length}</strong> job${data.length > 1 ? 's' : ''}`);
   
}

const initJobDescriptionPage = () => {
    let file;
    let openedAccordionIndex = 4;

    getJobList().then(data => {
        let url_string = window.location.href
        let url = new URL(url_string);
        let id = url.searchParams.get("id");
        jobList = data;

        let job = jobList.filter(job => job.id == id)[0];

		$('.position').html(`<strong>Position: ${job.title}</strong>`)
        $('.form-application input[name="position"]').val(job.title);
        $('.department').html(job.department);
        $('.title').html(job.title);
        $('.position-type').html(`<span>Position type:</span> ${job.workType}`);
        $('.form-application input[name="position-type"]').val(job.workType);
        $('.preferred-experience').html(`<span>Preferred experience:</span> ${job.experience} year${job.experience > 1 ? 's' : ''} (min)`);
        $('.form-application input[name="position-experience"]').val(job.experience);

        let summary = '';

        for (const sum of job.summary) {
            summary += '<div class="d-flex mb-3">';
            summary += '<tick-bullet color="primary"></tick-bullet>';
            summary += `<span class="ml-3">${sum}</span>`;
            summary += '</div>';
        }

        $('#summary').html(summary);

        let responsibilities = '';

        for (const responsibility of job.responsibilities) {
            responsibilities += '<div class="d-flex mb-3">';
            responsibilities += '<tick-bullet color="primary"></tick-bullet>';
            responsibilities += `<span class="ml-3">${responsibility}</span>`;
            responsibilities += '</div>';
        }

        $('#responsibilities').html(responsibilities);

        let requirements = '';

        for (const requirement of job.requirements) {
            requirements += '<div class="d-flex mb-3">';
            requirements += '<tick-bullet color="primary"></tick-bullet>';
            requirements += `<span class="ml-3">${requirement}</span>`;
            requirements += '</div>';
        }

        $('#requirements').html(requirements)

    });

    $('.mobile-toggle').on('click', function (e) {
        // $(this).children().children('.icon-wrapper').children().toggleClass('fa-plus fa-minus');
        $(this).addClass('triggered');
            
            $('.mobile-toggle').each(function () {
                if ($(this).hasClass('triggered')) {
                    $(this).removeClass('triggered');
                    if ($(this).parent().index() === openedAccordionIndex) {
                        openedAccordionIndex = -1;
                        $(this).children().children('.icon-wrapper').children().removeClass('fa-minus').addClass('fa-plus');
                    } else {
                        $('.details .container').children().eq(openedAccordionIndex).children('.mobile-toggle').children().children('.icon-wrapper').children().removeClass('fa-minus').addClass('fa-plus');
                        $(this).children().children('.icon-wrapper').children().removeClass('fa-plus').addClass('fa-minus');
                        openedAccordionIndex = $(this).parent().index();
                        setTimeout(() => {
                            $('html, body').animate({
                                scrollTop: $(this).offset().top - 100
                            }, 400);
                        }, 400)
                    }
    
                    return;
                }
            }) 
        
    })

	$('.open-application-form').magnificPopup({
		closeMarkup: '<button title="Close (Esc)" type="button" class="mfp-close"><i class="ion-close-round mfp-close"></i></button>',
        fixedContentPos: true,
        overflowY: 'scroll',
        removalDelay: 500,
        callbacks: {
            beforeOpen: function() {
                this.st.mainClass = 'mfp-fade-in';
            },
            close: function() {
                $('.form-application')[0].reset();
                $(".filename").removeClass('uploaded').html('');
                recaptchaValidate = false;
            }
        }
    });

    $('.file-upload').on('click', function(e) {
		e.preventDefault();			
		$('#resume').trigger('click');
	})

    $('#agreement').on('change', function() {
        $('#agreement').parent().removeClass('invalid');
    })

	$('#resume').on('change', function() {
        $('.resume-upload').removeClass('invalid')
        file = this.files;
		$(".filename").addClass('uploaded').html(file[0].name);
	})

    $('.form-application').submit(function(e) {
        let isValid = true;
        $(this).children().children('.form-group').removeClass('invalid');
        e.preventDefault();
        let formdata  = $(this).serializeArray()
        let valid = validateField(formdata);
        if(valid.length) {
            isValid = false;
            valid.map(index => {
                $(this).children().children('.form-group').eq(index).addClass('invalid')
            });
        } 
        
        if(!file || !file.length) {
            isValid = false;
            $('.resume-upload').addClass('invalid');
        }

        if(!$('#agreement').is(':checked')) {
            isValid = false;
            $('#agreement').parent().addClass('invalid');
        }

        if(!recaptchaValidate) {
            isValid = false;
            $('.form-application .g-recaptcha').siblings().show();
        }

        if(isValid) {
            $.ajax( {
                url: 'job_mailer.php',
                type: 'POST',
                data: new FormData( this ),
                processData: false,
                contentType: false,
                success: function(data) {
                    let response = JSON.parse(data)
                    if($.magnificPopup.instance.isOpen) {
                        if(response.status === 200) {
                            $('#form-result').html(success)
                        }else {
                            $('#form-result').html(error)
                            $('#form-result .error').html(response.message)
                        }
                        $('#form-result .fs-btn').on('click', function() {
                            $.magnificPopup.close();
                        })
                    }
                },
            } );

            $('.form-application')[0].reset();
            $(".filename").removeClass('uploaded').html('');
            recaptchaValidate = false;
            grecaptcha.reset();
            
            $.magnificPopup.close();
            setTimeout(() => {
                $.magnificPopup.open({
                    items: {
                        src: $('#form-result'),            
                    },
                    showCloseBtn: false,
                    closeOnBgClick: false,
                    removalDelay: 500,
                    callbacks: {
                        beforeOpen: function() {
                            $('#form-result').html(loading)
                            this.st.mainClass = 'mfp-fade-in';
                        }
                    }
                })
            }, 510)
        }
    })

    if ($(window).outerWidth() > 991) {
        $('#responsibilities').addClass('show');
        $('#requirements').addClass('show');
        $('a[href="#responsibilities"]').children().children('.icon-wrapper').children().removeClass('fa-plus').addClass('fa-minus');
    } else {
        $('a[href="#requirements"]').children().children('.icon-wrapper').children().removeClass('fa-minus').addClass('fa-plus');
        $('#requirements').removeClass('show');
    }


    $(window).on('resize', function () {
        if ($(window).outerWidth() > 991) {
            $('#responsibilities').addClass('show');
            $('#requirements').addClass('show');
        }else {
            if($('a[href="#responsibilities"]').children().children('.icon-wrapper').children().hasClass('fa-plus')) {
                $('#responsibilities').removeClass('show');
            }else {
                $('#responsibilities').addClass('show');
            }

            if($('a[href="#requirements"]').children().children('.icon-wrapper').children().hasClass('fa-plus')) {
                $('#requirements').removeClass('show');
            }else {
                $('#requirements').addClass('show');
            }
        }
    });
}

export {initCareersPage, initJobDescriptionPage}