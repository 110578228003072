import 'bootstrap';
import 'jquery';
import 'popper.js';

import '../components/nav-header';
import '../components/off-canvas-bar';
import '../components/desktop-nav-content';
import '../components/tick-bullet';
import '../components/btn-arrow';
import '../components/enquiry-form';
import '../components/recaptcha';
import '../components/form-result-modal';
import '../components/footer';
import './main';
// import './matterport';

if (module.hot) {
  module.hot.accept();
}
