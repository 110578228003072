class FormResultModal extends HTMLElement {
    constructor() {
        super();
    }

    connectedCallback() {
        this.innerHTML = `
		<div id="form-result" class="white-popup mfp-with-anim mfp-hide">
			<div class="d-flex flex-column align-items-center">
				<h3>Submitting...</h3>
				<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: 30px auto;display: block;shape-rendering: auto;" width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
					<circle cx="50" cy="50" fill="none" stroke="#3776ff" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138">
					<animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="0.8264462809917356s" values="0 50 50;360 50 50" keyTimes="0;1"/>
					</circle>
				</svg>
			</div>
		</div>
        `
    }
}

customElements.define('form-result-modal', FormResultModal);